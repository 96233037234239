import { Link } from 'react-router-dom';
import backgroundImage from '../assets/not-found.jpg'
import { ROUTE_URI } from '../utils/RouteLinks';
import { useTranslation } from 'react-i18next';

export const NotFoundPage = () => {
  const { t } = useTranslation()
    return (
      <>
        <main className="relative isolate min-h-full">
          <img
            src={backgroundImage}
            alt=""
            className="absolute inset-0 -z-10 h-full w-full object-cover object-top"
          />
          <div className="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8">
            <p className="text-2xl font-semibold leading-8 text-white">{t('notFoundPage.404')}</p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">{t('notFoundPage.header')}</h1>
            <p className="mt-4 text-2xl text-white/70 sm:mt-6">{t('notFoundPage.text.0')}</p>
            <p className="text-xl text-white/70 sm:mt-3">{t('notFoundPage.text.1')}</p>
            <div className="mt-10 flex justify-center">
              <Link to={ROUTE_URI.HOME} className="text-lg font-semibold leading-7 text-white">
                <span aria-hidden="true">&larr;</span> {t('notFoundPage.backToStart')}
              </Link>
            </div>
          </div>
        </main>
      </>
    )
  }
