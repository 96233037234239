import React, { useRef } from 'react';
import backgroundImage from '../assets/main-image.webp';
import { Stats } from '../components/FrontPageStats';
import SponsorsSection from '../sections/SponsorsSection';
import { useTranslation } from 'react-i18next';
import { EventsSection } from '../sections/EventsSection';
import { Link } from 'react-router-dom';
import { ROUTE_URI } from '../utils/RouteLinks';

export const MainPage = () => {
  const { t } = useTranslation()
  const missionSection = useRef(null);
  const sponsorSection = useRef(null);

  const scrollToSection = (ref) => ref.current.scrollIntoView({ behavior: 'smooth' });

  return <>
    <div className="flex flex-col">
      <header className="relative isolate overflow-hidden min-h-[40vh] md:min-h-[90vh] w-full max-h-screen">
        <img
          src={backgroundImage}
          alt=""
          className="absolute inset-0 -z-10 h-full overflow-x-hidden  w-full object-cover justify-self-center"
        />
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="mx-auto max-w-2xl px-6 py-32 sm:py-48 lg:py-56">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">{t('mainPage.headerHeader')}</h1>
            <p className="mt-6 text-lg leading-8 text-gray-300">{t('mainPage.headerText')}</p>
            <div className="mt-10 flex justify-center space-x-4 items-center">
              <div className="flex flex-1 justify-end">
                <button onClick={() => scrollToSection(sponsorSection)}>
                  <div className="p-2 rounded-md border-2 border-white text-sm font-bold text-white shadow-sm hover:bg-[#FF9119]/90">{t('shared.sponsors')}</div>
                </button>
              </div>
              <div className="flex flex-1 justify-start">
                <Link to={ROUTE_URI.CONTRIBUTIONS}>
                  <div
                    className="p-2 rounded-md border-white border-2 text-sm font-bold text-white shadow-sm hover:bg-[#FF9119]/90">
                    {t('mainPage.donateButton')}
                  </div>
                </Link>
              </div>
            </div>
            <div className="mt-10 flex flex-row items-center justify-center">
              <button onClick={() => scrollToSection(missionSection)} className="flex-col">
                <div className="text-sm font-semibold leading-6 text-white">{t('shared.readMore')}</div>
                <span className="text-sm font-semibold leading-6 text-white" aria-hidden="true">{'\u2193'}</span>
              </button>
            </div>
          </div>
        </div>
      </header>
      <div className="flex flex-col container bg-white mx-auto mt-36 max-w-7xl flex-1 px-10 gap-y-36 gap-x-36">
        <div ref={missionSection}>
          <Stats />
        </div>
        <EventsSection />
        <div ref={sponsorSection}>
          <SponsorsSection />
        </div>
      </div>
    </div>
  </>
}