import { ContributionsLevelsSection } from '../sections/ContributionsLevelsSection';
import { SwishSection } from '../sections/SwishSection';


export const ContributionsPage = () => {
  return (<>
    <ContributionsLevelsSection/>
    <SwishSection/>
    </>
  )
}
