import { XCircleIcon } from '@heroicons/react/20/solid'

export const ErrorAlert = () => {
    return (
        <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
                <div className="flex-shrink-0">
                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">Ett problem uppstod, testa något av följande</h3>
                    <div className="mt-2 text-sm text-red-700">
                        <ul className="list-disc space-y-1 pl-5">
                            <li>Försök igen</li>
                            <li>Kontakta oss genom alternativa vägar som ni finner på sidan</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
