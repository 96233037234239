import {Link} from 'react-router-dom';
import React from 'react';

export type SponsorImageProps = {
    src?: string,
    alt: string,
    href?: string,
    showName?: boolean
}


const SponsorField = (sponsor: SponsorImageProps, index: number) => {
    const ImageOrText = () =>
        sponsor.src ? (
            <div>
                <img
                    className="h-36 w-full object-contain"
                    key={index}
                    src={sponsor.src}
                    alt={sponsor.alt}
                    width={158}
                    height={48}
                />
                {sponsor.showName && <p className="text-center text-lg font-semibold text-gray-600">{sponsor.alt}</p>}
            </div>
        ) : (
            <h1
                className="h-36 text-2xl flex text-center  items-center justify-center content-center font-semibold leading-16 text-gray-600"
            >
                {sponsor.alt}
            </h1>
        );
    return (
        <div className="bg-gray-400/5 p-4 sm:p-6 flex justify-center items-center flex-wrap">
            {sponsor.href ?
                (<Link to={`${sponsor.href}`} target="_blank" rel="noreferrer"><ImageOrText /></Link>) :
                (<ImageOrText />)
            }
        </div>
    );
}

export default SponsorField