import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from "./locales/en-US/translation.json";
import translationSV from "./locales/sv-SE/translation.json";
import translationDE from "./locales/de-DE/translation.json";
import translationAR from "./locales/ar-SY/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  sv: {
    translation: translationSV
  },
  de: {
    translation: translationDE
  },
  ar: {
    translation: translationAR
  }
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'sv',
    supportedLngs: ['sv', 'en', 'de', 'ar'],
    debug: true,
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage']
    },
    resources,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;