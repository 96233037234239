import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom';
import { ROUTE_URI } from '../utils/RouteLinks';
import { useTranslation } from 'react-i18next';


export const ProjectsMenu = ({closeMobileMenu, textColor, extraTextClasses, wrapperClasses}) => {
  const { t } = useTranslation()

  const solutions = [
    { name: t('navbar.projectCountries.0.name'), translationKey: 'syria', description: t('navbar.projectCountries.0.description'), href: ROUTE_URI.PROJECTS.SYRIA, icon: "fi fi-sy", },
    { name: t('navbar.projectCountries.1.name'), translationKey: 'lebanon', description: t('navbar.projectCountries.1.description'), href: ROUTE_URI.PROJECTS.LEBANON, icon: "fi fi-lb" },
    { name: t('navbar.projectCountries.2.name'), translationKey: 'egypt', description: t('navbar.projectCountries.2.description'), href: ROUTE_URI.PROJECTS.EGYPT, icon: "fi fi-eg", },
  ]

  return (
    <div className={`${wrapperClasses}`}>
    <Popover className="relative">
      <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
        <span className={`${extraTextClasses} ${textColor.main}`}>{t('navbar.projects')}</span>
        <ChevronDownIcon className={`h-5 w-5 ${textColor.main}`} aria-hidden="true" />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-90 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-90 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
          <div
            className="w-screen max-w-xs flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
            <div className="p-1">
              {solutions.map((item) => (
                <Link to={item.href} onClick={closeMobileMenu}>
                  <div key={item.name} className="group relative flex items-center gap-x-6 rounded-lg p-2 hover:bg-gray-50">
                    <div className=" flex h-11 w-11 flex-none text-center justify-center items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                      <div className={`${item.icon} h-6 w-6 text-gray-600`} aria-hidden="true" />
                    </div>
                    <div className={`${extraTextClasses} ${textColor.subContent} hover:''`}>{item.name}</div>
                    <div className="font-semibold text-gray-500">{item.description}</div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
    </div>

  )
};