import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const countryCodes = [
  {id: 'US', languageCode: 'en', placeholder: '+1 (555) 987-6543'},
  {id: 'DE', languageCode: 'de', placeholder: '+49 171 1234567'},
  {id: 'SE', languageCode: 'sv', placeholder: '+46 70 123 4567'}]
export default function PhoneNumberInputField({value, setValue}) {
  const { t, i18n } = useTranslation()
  const [selectedCountryCode, setSelectedCountryCode] = useState(countryCodes.find(cc => cc.languageCode === i18n.language) || countryCodes.find(cc => cc.languageCode === 'sv') )
  return (
    <div className="md:w-1/2 block mx-auto">
      <label htmlFor="phone-number" className="block text-sm font-medium leading-6 text-gray-900">
        {t('forms.phoneNumber')}
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 flex items-center">
          <label htmlFor="country" className="sr-only">
            Country
          </label>
          <select
            
            id="country"
            name="country"
            defaultValue={selectedCountryCode.id}
            onChange={e => setSelectedCountryCode(countryCodes.find(countryCode => e.target.value === countryCode.id))}
            className="h-full rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
          >
            {countryCodes.map(countryCode => <option key={countryCode.id} value={countryCode.id}>{countryCode.id}</option>)}
          </select>
        </div>
        <input
          type="text"
          name="phone-number"
          id="phone-number"
          value={value}
          onChange={event => setValue(event.target.value)}
          className="block w-full rounded-md border-0 py-1.5 pl-16 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder={selectedCountryCode.placeholder}
        />
      </div>
    </div>
  )
}