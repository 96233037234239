import axios from 'axios';
import {ContactForm} from "../pages/ContactPage";
import {AutoGiroContactForm} from "../components/AutoGiroModal";
import {EventInterest} from "../types/EventSectionTypes";

export const postContactRequest = async (data: ContactForm) => {
    return axios({
    method: 'post',
    url: 'https://il5mfvdxej.execute-api.us-east-1.amazonaws.com/production/contact',
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export const postAutogiroRequest = async (data: AutoGiroContactForm) => {
    return axios({
        method: 'post',
        url: 'https://il5mfvdxej.execute-api.us-east-1.amazonaws.com/production/autogiro',
        data: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

export const postActivityInterest = async (data: EventInterest) => {
    return axios({
        method: 'post',
        url: 'https://il5mfvdxej.execute-api.us-east-1.amazonaws.com/production/activity-interest',
        data: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}


export const verifyCaptcha = async (email: string, recaptchaToken: string) => {
  console.warn('data is', recaptchaToken)
  return await axios.post('https://your-api-gateway-url.com/verify', {
    email,
    recaptchaToken
  }).then(response => {
    console.warn(response.data);
  });
}