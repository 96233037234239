import jhoniImage from '../assets/staff-images/shrunk/IMG_5790.jpg'

export const ThankYouPage = () => {
    return (
        <section className="relative isolate overflow-hidden min-h-[82vh] bg-white px-6 py-24 sm:py-32 lg:px-8">
            {/*<div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />*/}
            {/*<div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />*/}
            <div className="mx-auto max-w-2xl lg:max-w-4xl">
                <figure className="mt-10">
                    <blockquote
                        className="text-xl mb-10 font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
                        <p>

                        </p>
                    </blockquote>
                    <blockquote
                        className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
                        <p>
                            "Ett stort tack från oss alla på OneHand för ert val att stödja vårt arbete. Ert bidrag är den mest betydande faktorn i vårt arbete. Utan er hjälp skulle vi inte ha kunnat hjälpa ett enda barn. Varje donerad krona gör en betydande skillnad."
                        </p>
                    </blockquote>
                    <figcaption className="mt-16">
                        <img
                            className="mx-auto h-36 w-30 rounded-full"
                            src={jhoniImage}
                            alt=""
                        />
                        <div className="mt-4 flex items-center justify-center space-x-3 text-base">
                            <div className="font-semibold text-gray-900">Jhoni Zenno</div>
                            <svg viewBox="0 0 2 2" width={3} height={3} aria-hidden="true" className="fill-gray-900">
                                <circle cx={1} cy={1} r={1} />
                            </svg>
                            <div className="text-gray-600">Grundare OneHand</div>
                        </div>
                    </figcaption>
                    <blockquote className="text-center mt-16 leading-8 text-gray-900 sm:text-xl sm:leading-9">
                        <p>För att visa vår tacksamhet kommer vi att kontakta er så snart vi får möjlighet.</p>
                    </blockquote>
                </figure>
            </div>
        </section>
    )
}
