import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getTextAlignment } from '../../utils/StyleUtils';

function ProjectV2 ({
                           image,
                           texts,
                           header,
                           facilityName,
                           ourRoles,
                           theirRoles,
                           contactInformation = undefined
                         }){

  const {t, i18n} = useTranslation()

  const getHelpList = (helpingParty, roles, children) => {
    return <li className="py-8 flex-1">
      <dl className="flex flex-col lg:flex-row gap-3">
        <dd className="w-full flex-none text-lg font-semibold tracking-tight text-gray-900">
          {helpingParty} {t('projectsPage.helpingWith')}
          {roles.map(role => (
            <dd className="mt-4 flex items-center gap-x-3 text-base leading-7 text-gray-600">
              <svg viewBox="0 0 2 2" className="h-1.5 w-1.5 flex-none fill-gray-300"
                   aria-hidden="true">
                <circle cx={1} cy={1} r={1} />
              </svg>
              {role}
            </dd>
          ))}
        </dd>
      </dl>
      {children}
    </li>;
  }

  const getContactInfo = () => {
    return (
      <div className="gap-0">
        <p className="text-base leading-7 font-semibold tracking-tight text-gray-900">{t('projectsPage.contactInformationTitle')}: </p>
        {contactInformation.name && <p className="text-base leading-7 tracking-tight text-gray-900">{contactInformation.name}</p>}
        {contactInformation.phone && <p style={getTextAlignment(i18n.language)} className="text-base leading-7 tracking-tight text-gray-900">{contactInformation.phone}</p>}
        {contactInformation.website && <a href={contactInformation.website} className="text-base leading-7 tracking-tight text-blue-500">{contactInformation.website}</a>}
      </div>
    )
  }

  return (
    <div className="bg-white py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="flex flex-col gap-16 lg:items-start lg:justify-between mx-auto w-full">

          {/* Text and Image Container */}
          <div className="flex flex-col lg:flex-row w-full gap-16">
            {/* Text Group */}
            <div className="flex flex-col gap-6">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{header}</h2>
              {texts && texts.map(text => <p className="mt-6 text-lg leading-8 text-gray-600">{text}</p>)}
              {contactInformation && getContactInfo(contactInformation)}
            </div>

            {/* Image */}
            <img
              src={image}
              alt=""
              className="aspect-[6/5] lg:max-w-lg lg:flex-auto rounded-2xl bg-gray-50 object-cover lg:h-[34.5rem]"
            />
          </div>

          {/* List Container */}
          <div className="w-full">
            <ul className="divide-gray-100 flex flex-col lg:flex-row justify-center gap-16">
              {getHelpList(facilityName, theirRoles)}
              {getHelpList("One Hand", ourRoles,
                <div className="mt-8 flex items-end border-t border-gray-100 pt-8">
                  <dd className="text-base leading-7 text-gray-600">
                    Ett barn kostar skolan 100 kr per månad eller 1200 kr per år.
                    Hjälp oss att låta barnen få gå kvar i skolan.
                  </dd>
                </div>)}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

ProjectV2.propTypes = {
  image: PropTypes.any,
  mainText: PropTypes.string,
  subText: PropTypes.string,
  header: PropTypes.string,
  facilityName: PropTypes.string,
  ourRoles: PropTypes.arrayOf(PropTypes.string),
  theirRoles: PropTypes.arrayOf(PropTypes.string),
  contactInformation: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.number,
    website: PropTypes.string
  })
}

export default ProjectV2