import i18n from "../i18n";
import { Menu } from "@headlessui/react";
import { GlobeEuropeAfricaIcon } from "@heroicons/react/20/solid";
import { useLocation } from "react-router-dom";

const changeLanguage = (languageCode) => {
  if (languageCode === "ar") {
    document.documentElement.setAttribute("dir", "rtl");
  } else {
    document.documentElement.setAttribute("dir", "ltr");
  }
  return i18n.changeLanguage(languageCode);
};

export const LanguageSelector = () => {
  const { pathname } = useLocation();
 
  let textColor = "text-gray-900";
  if (pathname === "/") {
    textColor = "text-white";
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className={`flex items-center rounded-full bg-none ${textColor} hover:text-gray-400 focus:outline-none focus:ring-none focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100`}>
          <GlobeEuropeAfricaIcon
            aria-hidden="true"
            className="h-7 w-7 absolute left-0"
          />
        </Menu.Button>
      </div>

      <Menu.Items
        transition
        className="absolute left-1 z-10 mt-2 w-5 origin-top-right rounded-md bg-none shadow-none ring-0 ring-black ring-opacity-0 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="py-1">
          <Menu.Item>
            <div>
              <span
                className="fi fi-se"
                style={{ cursor: "pointer", margin: "8px 0px" }}
                onClick={() => changeLanguage("sv")}
              />
            </div>
          </Menu.Item>
          <Menu.Item>
            <div>
              <span
                className="fi fi-us"
                style={{ cursor: "pointer" }}
                onClick={() => changeLanguage("en")}
              />
            </div>
          </Menu.Item>
          <Menu.Item>
            <div>
              <span
                className="fi fi-de"
                style={{ cursor: "pointer" }}
                onClick={() => changeLanguage("de")}
              />
            </div>
          </Menu.Item>
          <form action="#" method="POST">
            <Menu.Item>
              <div
                className="flex gap-x-0"
                style={{
                  margin: "8px 0px",
                  width: "24px",
                  height: "16px",
                  position: "relative",
                  cursor: "pointer",
                }}
                onClick={() => changeLanguage("ar")}
              >
                <span
                  className="fi fi-lb"
                  style={{
                    position: "absolute",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    clipPath: "inset(0 0 0 50%)",
                  }}
                />
                <span
                  className="fi fi-sy"
                  style={{
                    position: "absolute",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    clipPath: "inset(0 50% 0 0)",
                  }}
                />
              </div>
            </Menu.Item>
          </form>
        </div>
      </Menu.Items>
    </Menu>
  );
};
