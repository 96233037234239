import { ClockIcon, LockClosedIcon } from '@heroicons/react/20/solid';
import QRSwish from '../assets/QR-OneHand.png'
import { useTranslation } from 'react-i18next';

export const SwishSection = () => {
  const {t} = useTranslation()
  const getIcon = (index) => {
    switch (index) {
      case 0: return <LockClosedIcon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
      case 1: return <ClockIcon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
      default: return null
    }
  }
  return (
    <div className="relative isolate">
    <svg
      className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
      aria-hidden="true"
    >
      <defs>
        <pattern
          id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
          width={200}
          height={200}
          x="50%"
          y={-1}
          patternUnits="userSpaceOnUse"
        >
          <path d="M100 200V.5M.5 .5H200" fill="none" />
        </pattern>
      </defs>
      <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
        <path
          d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
          strokeWidth={0}
        />
      </svg>
      <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
    </svg>
    <div className="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-16">
      <div className="px-6 lg:px-0 lg:pr-4 lg:pt-4">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">{t('contributionPage.swish.oneTimeGift')}</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t('contributionPage.swish.or')} <b>Swisha</b> {t('contributionPage.swish.inAGift')}</p>
          <p className="mt-6 text-lg leading-8 text-gray-600">{t('contributionPage.swish.wouldYouRather')}</p>
          <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
            {t('contributionPage.swish.benefits', {returnObjects: true}).map((benefit, index) => (
              <div key={benefit.reason} className="relative pl-9">
                <dt className="inline font-semibold text-gray-900">
                  {getIcon(index)}
                  {benefit.reason}
                </dt>{' '}
                <dd className="inline">{benefit.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
      <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
            <img src={QRSwish} alt="swish-qr-code" className="mx-auto sm:max-w-[8rem] md:max-w-[22.875rem] drop-shadow-xl rounded-t-xl bg-gray-800 ring-1 ring-white/10"/>
      </div>
    </div>
  </div>
  )
}