import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

export default function EmailInputField({
                                          value,
                                          setValue,
                                          placeholder = 'john.doe@example.com',
                                          showLabel,
                                          showInvalidMessage = true,
                                          isLoading = false,
                                        }) {
  const { t } = useTranslation()
  const [isValid, setIsValid] = useState(true);
  const [hasTouched, setHasTouched] = useState(!!value);


  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }

  useEffect(() => {
    if (hasTouched) {
      setIsValid(validateEmail(value));
    }
  }, [value, hasTouched]);

  return (
    <div>
      {showLabel && (<label htmlFor="email" className="block text-sm mb-2 font-medium leading-6 text-gray-900">
        {t('forms.email')}
      </label>)}
      <div className="relative rounded-md shadow-sm">
        <input
          type="email"
          name="email"
          id="email"
          className={`block w-full rounded-md border-0 py-1.5 pr-10 ${!hasTouched || isValid ? 'text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-300' : 'text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300'} focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6`}
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            setValue(e.target.value)
            setHasTouched(true);
          }}
          aria-invalid="false"
          aria-describedby="email-error"
          disabled={isLoading}
        />
        {!isValid && hasTouched && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>)}
      </div>
      {showInvalidMessage && !isValid && hasTouched && (
        <p className="mt-2 text-sm text-red-600" id="email-error">{t('forms.invalidEmail')}</p>)}
    </div>
  )
}