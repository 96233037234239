import { Link } from "react-router-dom";
import { ROUTE_URI } from "../../utils/RouteLinks";
import { useTranslation } from 'react-i18next';
import { ProjectV1 } from './ProjectV1';
import image1 from '../../assets/syria/sadad/Syrien saddat.jpg'
import image2 from '../../assets/syria/sadad/13.JPG'
import image3 from '../../assets/syria/sadad/syria-1.jpg';
import image4 from '../../assets/syria/sadad/12.jpg'
import imageFacilitySadad from '../../assets/syria/sadad/1.jpg'

export const SyriaProjectPage = () => {
  const { t, i18n } = useTranslation()
  return (
    <div className="overflow-hidden bg-white py-32">
      <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
        <div
          className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
          <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t('projectsPage.syria.countryName')}</h2>
            <p className="mt-6 text-xl leading-8 text-gray-600">
              {t('projectsPage.syria.mainText')}
            </p>
            <div className="mt-10 flex">
              <Link to={ROUTE_URI.CONTRIBUTIONS}
                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >{t('projectsPage.contributeButton')} <span aria-hidden="true">{i18n.language === 'ar' ? '\u2190' : '\u2192' }</span>

              </Link>
            </div>
          </div>
          <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
            <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
              <img
                src={image1}
                alt=""
                className="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover"
              />
            </div>
            <div
              className="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
              <div className="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
                <img
                  src={image2}
                  alt=""
                  className="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
                />
              </div>
              <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
                <img
                  src={image3}
                  alt=""
                  className="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
                />
              </div>
              <div className="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
                <img
                  src={image4}
                  alt=""
                  className="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProjectV1 image={imageFacilitySadad} header={t('projectsPage.syria.projects.0.header')}
                 texts={t('projectsPage.syria.projects.0.texts', { returnObjects: true })}
                 facilityName={t('projectsPage.syria.projects.0.facilityName')}
                 ourRoles={t('projectsPage.syria.projects.0.ourRoles', { returnObjects: true })}
                 theirRoles={t('projectsPage.syria.projects.0.theirRoles', { returnObjects: true })}
                 contactInformation={{
        name: t('projectsPage.syria.projects.0.contactInformation.name'),
        phone: t('projectsPage.syria.projects.0.contactInformation.phone'),
        website: t('projectsPage.syria.projects.0.contactInformation.website'),
      }} />

    </div>
  )
}
