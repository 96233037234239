import React, {useState} from 'react';
import RadioSelection from "./RadioSelections";
import PhoneNumberInputField from "./form/PhoneNumber";
import BankAccountNumberInputField from "./form/BankAccountNumberInputField";
import EmailInputField from "./form/EmailInputField";
import GenericInputField from "./form/GenericInputField";
import {useTranslation} from "react-i18next";
import {postAutogiroRequest} from "../services/ApiGatewayService";
import {currencies, Currency} from "./form/AmountInputField";
import {useNavigate} from "react-router-dom";
import {ROUTE_URI} from "../utils/RouteLinks";

export type Frequency = {
    value: string,
    label: string,
    priceSuffix: string
}

export type Frequencies = {
    oneTime: string;
    monthly: string;
    annually: string;
}

export type ContributionLevel = {
    name: string;
    id: string;
    href: string;
    price: {
        oneTime: string;
        monthly: string;
        annually: string;
    };
    description: string;
    features: string[];
    mostPopular: boolean;
};

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    preselectedValue?: string;
    contributionLevels: ContributionLevel[],
    frequency: Frequency
}

export interface AutoGiroContactForm {
    phone: string;
    firstName: string;
    lastName: string;
    email: string;
    contribution: string;
    frequency: string
    securityNumber: string;
    bankName: string;
    bankAccount: string;
}

const AutoGiroModal: React.FC<ModalProps> = ({isOpen, onClose, preselectedValue, contributionLevels, frequency}) => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [phone, setPhone] = useState()
    const [email, setEmail] = useState()
    const [securityNumber, setSecurityNumber] = useState()
    const [bankAccount, setBankAccount] = useState()
    const [bankName, setBankName] = useState()
    const [customContribution, setCustomContribution] = useState()
    const [loading, setLoading] = useState(false)
    const [currency, setCurrency] = useState<Currency|undefined>(currencies.find(currency => currency.languageCode === i18n.language) || currencies.find(currency => currency.languageCode === 'sv'))
    if (!isOpen) return null;

    const resetFields = () => {
        setFirstName(undefined);
        setLastName(undefined);
        setPhone(undefined);
        setEmail(undefined);
        setSecurityNumber(undefined);
        setBankAccount(undefined);
        setBankName(undefined);
    };
    async function handleSubmit (e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if (!areAllFieldsFilled()) {
            return;
        }
        setLoading(true)

        const contribution = !!customContribution ? `${customContribution}` : contributionLevels.find(level => level.id === preselectedValue)!.price[frequency.value as keyof Frequencies]
        const data: AutoGiroContactForm = {
            phone: phone!,
            firstName: firstName!,
            lastName: lastName!,
            email: email!,
            securityNumber: securityNumber!,
            bankAccount: bankAccount!,
            bankName: bankName!,
            contribution: `${contribution} ${currency!.id}`,
            frequency: frequency.value
        }

        try {
            await postAutogiroRequest(data)
            resetFields()
            onClose()
        } catch (err){
            console.warn(err)
        } finally {
            setLoading(false)
            navigate(ROUTE_URI.THANK_YOU)
        }
    }
    const areAllFieldsFilled = () => (firstName && lastName && phone && email && securityNumber && bankAccount && bankName);

    return (
        <div className="fixed inset-0 flex bg-gray-500 bg-opacity-50 justify-center items-center w-full z-50">
            <div className="bg-white overflow-y-scroll p-6 rounded shadow-lg w-[90vw] h-[90vh] md:h-3/4 md:w-3/4">
                <h2 className="text-xl mb-4 md:w-1/2 block mx-auto">Formulär för autogiro</h2>
                <form onSubmit={handleSubmit} action="#" method="POST">
                    <div className="mb-4 md:w-1/2 block mx-auto">
                        <GenericInputField id={'autoGiro-firstName'} label={t('forms.firstName')} type={'text'}
                                           placeholder={'John'} value={firstName} setValue={setFirstName}/>
                    </div>
                    <div className="mb-4 md:w-1/2 block mx-auto">
                        <GenericInputField id={'autoGiro-lastName'} label={t('forms.lastName')} type={'text'}
                                           placeholder={'Doe'} value={lastName} setValue={setLastName}/>
                    </div>
                    <div className="mb-4">
                        <PhoneNumberInputField value={phone} setValue={setPhone} />
                    </div>
                    <div className="mb-4 md:w-1/2 block mx-auto">
                        <EmailInputField value={email} setValue={setEmail} isLoading={loading} showLabel={true} />
                    </div>
                    <div className="mb-4 md:w-1/2 block mx-auto">
                        <GenericInputField id={'autoGiro-securityNumber'} label={t('forms.securityNumber')} type={'text'}
                                           placeholder={''} value={securityNumber} setValue={setSecurityNumber}/>
                    </div>
                    <div className="mb-4 md:w-1/2 block mx-auto">
                        <GenericInputField id={'autoGiro-bankName'} label={t('forms.bankName')} type={'text'}
                                           placeholder={'Swedbank'} value={bankName} setValue={setBankName}/>
                    </div>
                    <div className="mb-4">
                        <BankAccountNumberInputField setValue={setBankAccount}/>
                    </div>
                    <div className="mb-4">
                        <RadioSelection contributionLevels={contributionLevels}
                                        preSelectedLevelId={preselectedValue}
                                        setCustomContribution={setCustomContribution}
                                        customContribution={customContribution}
                                        currency={currency}
                                        setCurrency={setCurrency}
                        />
                    </div>
                    {/* Add other form fields as needed */}
                    <div className="flex justify-end justify-between mt-16">
                        <button
                            type="button"
                            className="rounded-md w-36 bg-white px-3 mr-2 py-2 text-sm font-semibold text-gray-900 shadow-sm
                                ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            onClick={onClose}>
                            {t('forms.cancel')}
                        </button>
                        <button
                            disabled={!areAllFieldsFilled() || loading}
                            className="flex justify-center rounded-md w-36 disabled:bg-indigo-300 bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            type="submit">
                            {loading && <div className="flex items-center justify-center animate-spin h-5 w-5 mr-3 bg-gradient-to-tr from-indigo-300 to-white rounded-full">
                                <div className="h-3 w-3 rounded-full bg-indigo-300">
                                </div>
                            </div>}
                            {t('forms.submit')}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AutoGiroModal;
