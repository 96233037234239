import oneHandLogo from "./assets/logo-solo.png";
import stMariaLogo from "./assets/events/st-maria.jpg";
import morJohannesLogo from "./assets/events/mor-johannes.jpeg";
import morJacobLogo from "./assets/events/mor-jacob.jpeg";
import stGabriel from "./assets/events/st-gabriel.jpeg";
import stPetrus from "./assets/events/st-petrus.png";
import stGeorgis from "./assets/events/st-georgis.jpeg";
import {EventType, RangedEvent, SingleEvent} from "./types/EventSectionTypes";

export const events: (RangedEvent|SingleEvent)[] = [
    {
        id: 1,
        date: 'March 17th, 2024',
        time: '4:00 PM',
        datetime: '2024-03-17T16:00+01:00',
        name: 'OneHand Dagen',
        imageUrl: oneHandLogo,
        location: 'Tumbascenen',
        type: EventType.SINGLE
    },
    {
        id: 2,
        date: 'March 24th, 2024',
        time: '10:00 AM',
        datetime: '2024-03-24T10:00+01:00',
        name: 'Försäljning av palmljus och hantverk',
        imageUrl: stGabriel,
        location: 'S:t Gabriel, Södertälje',
        type: EventType.SINGLE
    }, {
        id: 3,
        date: 'March 24th, 2024',
        time: '10:00 AM',
        datetime: '2024-03-24T10:00+01:00',
        name: 'Försäljning av palmljus och hantverk',
        imageUrl: morJacobLogo,
        location: 'S:t Jacob, Södertälje',
        type: EventType.SINGLE
    }, {
        id: 4,
        date: 'March 31th, 2024',
        time: '10:00 AM',
        datetime: '2024-03-31T10:00+01:00',
        name: 'Försäljning av palmljus och hantverk',
        imageUrl: stMariaLogo,
        location: 'S:t Maria, Tumba',
        type: EventType.SINGLE
    },
    {
        id: 5,
        date: 'March 31th, 2024',
        time: '10:00 AM',
        datetime: '2024-03-31T10:00+01:00',
        name: 'Försäljning av palmljus och hantverk',
        imageUrl: morJohannesLogo,
        location: 'Mor Johannes, Märsta',
        type: EventType.SINGLE
    },
    {
        id: 6,
        date: 'March 31th, 2024',
        time: '10:00 AM',
        datetime: '2024-03-31T10:00+01:00',
        name: 'Försäljning av palmljus och hantverk',
        imageUrl: stPetrus,
        location: 'S:t Petrus, Hallonbergen',
        type: EventType.SINGLE
    },
    {
        id: 7,
        date: 'March 31th, 2024',
        time: '10:00 AM',
        datetime: '2024-03-31T10:00+01:00',
        name: 'Försäljning av palmljus och hantverk',
        imageUrl: stGeorgis,
        location: 'S:t Georgis, Norsborg',
        type: EventType.SINGLE
    },
    {
        id: 9,
        date: 'April 7th, 2024',
        time: '10:00 AM',
        datetime: '2024-04-07T10:00+01:00',
        name: 'Försäljning av palmljus och hantverk',
        imageUrl: stMariaLogo,
        location: 'S:t Maria, Tumba',
        type: EventType.SINGLE
    }, {
        id: 10,
        date: 'April 7th, 2024',
        time: '10:00 AM',
        datetime: '2024-04-07T10:00+01:00',
        name: 'Försäljning av palmljus och hantverk',
        imageUrl: morJohannesLogo,
        location: 'Mor Johannes, Märsta',
        type: EventType.SINGLE
    }, {
        id: 11,
        date: 'April 7th, 2024',
        time: '10:00 AM',
        datetime: '2024-04-07T10:00+01:00',
        name: 'Försäljning av palmljus och hantverk',
        imageUrl: stPetrus,
        location: 'S:t Petrus, Hallonbergen',
        type: EventType.SINGLE
    }, {
        id: 12,
        date: 'April 7th, 2024',
        time: '10:00 AM',
        datetime: '2024-04-07T10:00+01:00',
        name: 'Försäljning av palmljus och hantverk',
        imageUrl: stGeorgis,
        location: 'S:t Georgis, Norsborg',
        type: EventType.SINGLE
    },
    {
        id: 13,
        fromDate: '2024-07-10T10:00+01:00',
        toDate: '2024-08-10T10:00+01:00',
        name: 'Inplanerade företagsbesök',
        imageUrl: oneHandLogo,
        location: 'Stockholm',
        type: EventType.RANGE
    }
];
