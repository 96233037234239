import React from 'react';
import {useTranslation} from "react-i18next";

export type Currency = {
    id: string,
    symbol: string,
    languageCode: string
}
export const currencies: Currency[] = [{id: 'USD', symbol: '$', languageCode: 'en'}, {id: 'EUR', symbol: '€', languageCode: 'de'}, {id: 'SEK', symbol: 'kr', languageCode: 'sv'}]

type AmountInputProps = {
    setValue: React.Dispatch<React.SetStateAction<string>>;
    setCurrency: React.Dispatch<React.SetStateAction<Currency|undefined>>;
    value: string;
    currency: Currency;
}

const AmountInputField: React.FC<AmountInputProps> = ({setValue, setCurrency, currency, value}) => {
    const { t } = useTranslation()
    return (
        <div className="w-1/2 block mx-auto">
            <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                {t('forms.amount')}
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">{currency!.symbol}</span>
                </div>
                <input
                    type="text"
                    name="price"
                    id="price"
                    value={value}
                    className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="0.00"
                    onChange={e => setValue(e.target.value)}
                />
                <div className="absolute inset-y-0 right-0 flex items-center">
                    <label htmlFor="currency" className="sr-only">
                        Currency
                    </label>
                    <select
                        id="currency"
                        name="currency"
                        className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                        onChange={e => setCurrency(currencies.find(currency => e.target.value === currency.id))}
                        defaultValue={currency.id}
                    >
                        {currencies.map(currency => <option key={currency.id} value={currency.id}>{currency.id}</option>)}
                    </select>
                </div>
            </div>
        </div>
    )
}

export default AmountInputField;
