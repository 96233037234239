export enum EventType {
    RANGE = 'range',
    SINGLE = 'single'
}

export interface Event {
    id: number;
    name: string;
    imageUrl: string;
    location: string;
}

export interface SingleEvent extends Event {
    date: string;
    time: string;
    datetime: string;
    type: EventType.SINGLE;
}

export interface RangedEvent extends Event {
    fromDate: string;
    toDate: string;
    type: EventType.RANGE;
}

export interface EventInterest {
    id: number;
    datetime?: string;
    name: string;
    location: string;
    email: string
}