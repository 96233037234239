import React, {useState} from 'react';
import {BriefcaseIcon, BuildingOffice2Icon, EnvelopeIcon, PhoneIcon} from '@heroicons/react/24/outline'
import {postContactRequest,} from "../services/ApiGatewayService";
import {ErrorAlert} from "../components/Alerts";
import {ContactThankYou} from "../components/ContactThankYou";
import {useTranslation} from "react-i18next";
import {getTextAlignment} from "../utils/StyleUtils";

export interface ContactForm {
  phone: string;
  firstName: string;
  lastName: string;
  email: string;
  message: string;

}

export const ContactPage: React.FC = () => {
  const [formData, setFormData] = useState<ContactForm>({
    phone: '',
    firstName: '',
    lastName: '',
    email: '',
    message: ''
  });
  const [loading, setLoading] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { t, i18n } = useTranslation()


  function handleChange (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }
  async function handleSubmit (e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (loading || !areAllFieldsFilled()) {
      return;
    }

    try {
      await postContactRequest(formData)

      setShowThankYou(true);
      setFormData({
        phone: '',
        firstName: '',
        lastName: '',
        email: '',
        message: '',
      });
      setErrorMessage('');
    } catch (error) {
      // If failed, display an error message and keep the form data
      setErrorMessage('Failed to send the email. Please try again later.');
    } finally {
      // Reset the loading state after the request is completed (success or error)
      setLoading(false);
    }
  }

  const areAllFieldsFilled = () => {
    return Object.values(formData).every((field) => field.trim() !== '');
  };

  return <div className="text-white flex-grow relative isolate overflow-hidden pt-20">
    <div className="relative isolate bg-white">
      <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
        <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
          <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
            <div
              className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 ring-1 ring-gray-900/10 lg:w-1/2">
              <svg
                className="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                    width={200}
                    height={200}
                    x="100%"
                    y={-1}
                    patternUnits="userSpaceOnUse"
                  >
                    <path d="M130 200V.5M.5 .5H200" fill="none" />
                  </pattern>
                </defs>
                <rect width="100%" height="100%" strokeWidth={0} fill="white" />
                <svg x="100%" y={-1} className="overflow-visible fill-gray-50">
                  <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
                </svg>
                <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
              </svg>
            </div>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900">{t('contactPage.textHeader')}</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">{t('contactPage.text')}</p>
            <dl className="mt-10 space-y-4 text-base leading-7 text-gray-600">
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Adress</span>
                  <BuildingOffice2Icon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                </dt>
                <dd style={getTextAlignment(i18n.language)}>
                  Svampstigen 64
                  <br />
                  144 40 Rönninge
                  <br />
                  {t('contactPage.country')}
                </dd>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Telephone</span>
                  <PhoneIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                </dt>
                <dd>
                  <a style={getTextAlignment(i18n.language)} className="hover:text-gray-900" href="tel:+46 (073) 913-9357">
                    +46 (073) 913-9357
                  </a>
                </dd>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">E-post</span>
                  <EnvelopeIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                </dt>
                <dd>
                  <a className="hover:text-gray-900" href="mailto:info@one-hand.se">
                    info@one-hand.se
                  </a>
                </dd>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Organisation nummer</span>
                  <BriefcaseIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                </dt>
                <dd style={getTextAlignment(i18n.language)}>
                  802523-0585
                </dd>
              </div>
            </dl>
          </div>
        </div>

        {showThankYou && <div className="mx-auto flex max-w-xl lg:max-w-lg">
          <ContactThankYou/></div>}
        {!showThankYou && (<form onSubmit={handleSubmit} action="#" method="POST" className="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48">
            <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">

              <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                <div>
                  <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
                    {t('contactPage.firstName')}
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="text"
                      name="firstName"
                      onChange={(e) => handleChange(e)}
                      value={formData.firstName}
                      id="first-name"
                      autoComplete="given-name"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">
                    {t('contactPage.lastName')}
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="text"
                      name="lastName"
                      onChange={handleChange}
                      value={formData.lastName}
                      id="last-name"
                      autoComplete="family-name"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                    {t('contactPage.email')}
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="email"
                      name="email"
                      onChange={handleChange}
                      value={formData.email}
                      id="email"
                      autoComplete="email"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-gray-900">
                    {t('contactPage.phone')}
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="tel"
                      value={formData.phone}
                      onChange={handleChange}
                      name="phone"
                      id="phone-number"
                      autoComplete="tel"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="message"  className="block text-sm font-semibold leading-6 text-gray-900">
                    {t('contactPage.message')}
                  </label>
                  <div className="mt-2.5">
      <textarea
        name="message"
        id="message"
        onChange={handleChange}
        value={formData.message}
        rows={4}
        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        defaultValue={''}
      />
                  </div>
                </div>
              </div>
              <div className="mt-8 flex justify-end">
                <button disabled={!areAllFieldsFilled() || loading}
                  type="submit"
                  className="rounded-md disabled:bg-indigo-300 bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  {t('contactPage.submitButton')}
                </button>
´             </div>
              {errorMessage && <div className={"pt-6"}><ErrorAlert/></div>}
            </div>
          </form>)}
      </div>
    </div>
  </div>
}
