import React, {useState} from 'react';
import {CalendarIcon, CheckCircleIcon, MapPinIcon,} from '@heroicons/react/20/solid'
import EmailInputField from "../components/form/EmailInputField";
import {postActivityInterest} from "../services/ApiGatewayService";
import {events} from "../data";
import {useTranslation} from "react-i18next";
import {addDays, addHours, isAfter, parseISO} from "date-fns";
import {differenceInMonths} from "date-fns/differenceInMonths";
import {EventInterest, EventType, RangedEvent, SingleEvent} from "../types/EventSectionTypes";

export const EventsSection: React.FC = () => {
    const {t} = useTranslation()
    const [activeActivityId, setActiveActivityId] = useState<number | null>(null);
    const [email, setEmail] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>()
    const [registeredEvents, setRegisteredEvents] = useState<number[]>([])
    const handleRegisterClick = (id: number) => {
        setActiveActivityId(id);
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        const activity: RangedEvent | SingleEvent | undefined = events.find(meeting => meeting.id === activeActivityId)

        if (activity) {
            try {
                setIsLoading(true)
                const data: EventInterest = {
                    id: activity.id,
                    datetime: activity.type === EventType.SINGLE ? activity.datetime : undefined,
                    location: activity.location,
                    name: activity.name,
                    email: email
                };
                await postActivityInterest(data)
                setRegisteredEvents([...registeredEvents, activity.id])
                setActiveActivityId(null);
                setEmail('');
            } catch (err) {
                console.warn(err)
            } finally {
                setIsLoading(false)
            }
        }
    };

    const getDateTime = (datetime: string) => {
        const date = new Date(datetime);
        const locale = 'sv-SE'; // Change to the desired locale
        let formattedDate = date.toLocaleString(locale, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: undefined
        });
        return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    }

    const getDate = (datetime: string) => {
        const date = new Date(datetime);
        const locale = 'sv-SE'; // Change to the desired locale
        let formattedDate = date.toLocaleString(locale, {year: 'numeric', month: 'long', day: 'numeric'});
        return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    }

    const isBeforeNowWithBuffer = (datetime: string): boolean => {
        const nowLocal = new Date();
        const nowUTC = new Date(Date.UTC(nowLocal.getUTCFullYear(), nowLocal.getUTCMonth(), nowLocal.getUTCDate(), nowLocal.getUTCHours(), nowLocal.getUTCMinutes(), nowLocal.getUTCSeconds()));
        const nowCET = addHours(nowUTC, 1);
        const eventTime = parseISO(datetime);
        const eventTimeWithBuffer = addDays(eventTime, 1);
        return isAfter(eventTimeWithBuffer, nowCET);
    }

    const isWithinLastMonth = (datetime: string): boolean => {
        const nowLocal = new Date();
        const nowUTC = new Date(Date.UTC(nowLocal.getUTCFullYear(), nowLocal.getUTCMonth(), nowLocal.getUTCDate(), nowLocal.getUTCHours(), nowLocal.getUTCMinutes(), nowLocal.getUTCSeconds()));
        const nowCET = addHours(nowUTC, 1);
        const eventTime = parseISO(datetime);
        const monthsDifference = differenceInMonths(nowCET, eventTime);
        return monthsDifference < 1;
    }

    const RegistrationForm: React.FC<{ email: string, setEmail: Function, handleSubmit: Function }> = ({
                                                                                                           email,
                                                                                                           setEmail,
                                                                                                           handleSubmit
                                                                                                       }) => (
        <form onSubmit={(event) => handleSubmit(event)} className="flex items-center space-x-3">
            <EmailInputField value={email} setValue={setEmail} showLabel={false} showInvalidMessage={false}
                             isLoading={isLoading} />
            {!isLoading ?
                <button type="submit" className="px-4 py-1 bg-blue-500 text-white rounded">
                    {t('forms.submit')}
                </button> :
                <button type="button" className="px-4 py-1 bg-blue-200 text-white rounded" disabled>
                    <svg aria-hidden="true"
                         className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                         viewBox="0 0 100 101" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor" />
                        <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill" />
                    </svg>
                </button>
            }
        </form>
    );

    const RegisterButton: React.FC<{ meetingId: number, handleRegisterClick: Function }> = ({
                                                                                                meetingId,
                                                                                                handleRegisterClick
                                                                                            }) => (
        <button onClick={() => handleRegisterClick(meetingId)}
                className="rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100">
            {t('mainPage.upcomingEvents.interestButton')}
        </button>
    );

    function upcomingEvents() {
        return <>
            <h2 className="text-xl font-semibold leading-6 text-gray-900">{t('mainPage.upcomingEvents.header')}</h2>
            <div className="flex-auto">
                <ol className="mt-4 divide-y divide-gray-100 text-sm leading-6">
                    {events.filter(event => event.type === EventType.SINGLE ?
                        isBeforeNowWithBuffer(event.datetime) : isBeforeNowWithBuffer(event.toDate)).length > 0 ?
                        (events.filter(event => event.type === EventType.SINGLE ?
                            isBeforeNowWithBuffer(event.datetime) : isBeforeNowWithBuffer(event.toDate))
                            .map((meeting) => (
                                <li key={meeting.id}
                                    className="flex flex-col py-6 space-y-3 sm:space-y-0 sm:flex-row sm:items-center xl:static">
                                    <img src={meeting.imageUrl} alt=""
                                         className="h-14 w-14 flex-none rounded-full sm:mr-4" />
                                    <div className="flex-1">
                                        <h3 className="pr-10 mb-2 font-semibold text-gray-900">{meeting.name}</h3>
                                        <div
                                            className="flex flex-col sm:flex-row sm:items-center text-gray-500 space-y-2 sm:space-y-0 sm:space-x-4 pt-2 sm:pt-0">
                                            <div className="flex items-center space-x-2">
                                                <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                {meeting.type === EventType.SINGLE &&
                                                  <time
                                                    dateTime={meeting.datetime}>{getDateTime(meeting.datetime)}</time>}
                                                {meeting.type === EventType.RANGE && <div>
                                                  <time dateTime={meeting.fromDate}>{getDate(meeting.fromDate)}</time>
                                                  <span> - </span>
                                                  <time dateTime={meeting.toDate}>{getDate(meeting.toDate)}</time>
                                                </div>}
                                            </div>
                                            <div
                                                className="flex items-center space-x-2 sm:border-l sm:border-gray-400 sm:border-opacity-50 sm:pl-3.5">
                                                <MapPinIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                <dd>{meeting.location}</dd>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sm:ml-auto">
                                        {activeActivityId === meeting.id ? (
                                            <RegistrationForm email={email} setEmail={setEmail}
                                                              handleSubmit={handleSubmit} />
                                        ) : (
                                            registeredEvents.includes(meeting.id) ? (
                                                <CheckCircleIcon
                                                    className="self-center h-10 w-10 rounded-full bg-green-400 p-1 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-400"
                                                    aria-hidden="true" />
                                            ) : (
                                                <RegisterButton meetingId={meeting.id}
                                                                handleRegisterClick={handleRegisterClick} />
                                            )
                                        )}
                                    </div>
                                </li>
                            ))) : (
                            <div className="flex justify-center items-center font-semibold text-gray-900">
                                <p>{t('mainPage.upcomingEvents.noEvents')}</p>
                            </div>
                        )}
                </ol>
            </div>
        </>;
    }

    function previousEvents() {
        return <>
            <h2 className="text-xl mt-10 font-semibold leading-6 text-gray-400">{t('mainPage.passedEvents.header')}</h2>
            <div className="flex-auto">
                <ol className="mt-4 divide-y divide-gray-100 text-sm leading-6">
                    {events.filter(event => event.type === EventType.SINGLE ?
                        !isBeforeNowWithBuffer(event.datetime) && isWithinLastMonth(event.datetime) :
                        !isBeforeNowWithBuffer(event.toDate) && isWithinLastMonth(event.toDate)).length > 0 ?
                        (events.filter(event => event.type === EventType.SINGLE ?
                            !isBeforeNowWithBuffer(event.datetime) && isWithinLastMonth(event.datetime) :
                            !isBeforeNowWithBuffer(event.toDate) && isWithinLastMonth(event.toDate))
                            .map((meeting) => (
                                <li key={meeting.id}
                                    className="flex flex-col py-6 space-y-3 sm:space-y-0 sm:flex-row sm:items-center xl:static">
                                    <img src={meeting.imageUrl} alt=""
                                         className="h-14 w-14 flex-none rounded-full sm:mr-4" />
                                    <div className="flex-1">
                                        <h3 className="pr-10 mb-2 font-semibold text-gray-400">{meeting.name}</h3>
                                        <div
                                            className="flex flex-col sm:flex-row sm:items-center text-gray-400 space-y-2 sm:space-y-0 sm:space-x-4 pt-2 sm:pt-0">
                                            <div className="flex items-center space-x-2">
                                                <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                {meeting.type === EventType.SINGLE &&
                                                  <time
                                                    dateTime={meeting.datetime}>{getDateTime(meeting.datetime)}</time>}
                                                {meeting.type === EventType.RANGE && <div>
                                                  <time dateTime={meeting.fromDate}>{getDate(meeting.fromDate)}</time>
                                                  <span> - </span>
                                                  <time dateTime={meeting.toDate}>{getDate(meeting.toDate)}</time>
                                                </div>}
                                            </div>
                                            <div
                                                className="flex items-center space-x-2 sm:border-l sm:border-gray-400 sm:border-opacity-50 sm:pl-3.5">
                                                <MapPinIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                <dd>{meeting.location}</dd>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))) : (
                            <div className="flex justify-center items-center font-semibold text-gray-900">
                                <p>{t('mainPage.passedEvents.noEvents')}</p>
                            </div>
                        )}
                </ol>
            </div>
        </>;
    }

    return (
        <div className="bg-white">
            {upcomingEvents()}
            {previousEvents()}
        </div>
    )
}
