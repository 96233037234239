import React from 'react';
import { useTranslation } from 'react-i18next';

export const LookingForHelpSection = () => {
  const { t } = useTranslation()
  return (
    <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
      <div
        className="mx-auto flex max-w-2xl flex-col items-start justify-between gap-16 lg:mx-0 lg:max-w-none lg:flex-row">
        <div className="w-full lg:max-w-lg lg:flex-auto">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t('aboutPage.lookingForHelp.header')}</h2>
          <p className="mt-6 text-xl leading-8 text-gray-600">{t('aboutPage.lookingForHelp.text')}</p>
          <img
            src="https://images.unsplash.com/photo-1606857521015-7f9fcf423740?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1344&h=1104&q=80"
            alt=""
            className="mt-16 aspect-[6/5] w-full rounded-2xl bg-gray-50 object-cover lg:aspect-auto lg:h-[34.5rem]"
          />
        </div>
        <div className="w-full lg:max-w-xl lg:flex-auto">
          <h3 className="sr-only">{t('aboutPage.lookingForHelp.openPositions')}</h3>
          <ul className="-my-8 divide-y divide-gray-100">
            {t('aboutPage.lookingForHelp.openings', {returnObjects: true}).map((opening) => (
              <li key={opening.id} className="py-8">
                <dl className="relative flex flex-wrap gap-x-3">
                  <dt className="sr-only">Roll</dt>
                  <dd className="w-full flex-none text-lg font-semibold tracking-tight text-gray-900">
                    <a href={opening.href}>
                      {opening.role}
                      <span className="absolute inset-0" aria-hidden="true" />
                    </a>
                  </dd>
                  <dt className="sr-only">{t('aboutPage.lookingForHelp.roleDescription')}</dt>
                  <dd className="mt-2 w-full flex-none text-base leading-7 text-gray-600">{opening.description}</dd>
                  <dt className="sr-only">{t('aboutPage.lookingForHelp.roleLocation')}</dt>
                  <dd className="mt-4 flex items-center gap-x-3 text-base leading-7 text-gray-500">
                    <svg viewBox="0 0 2 2" className="h-1.5 w-1.5 flex-none fill-gray-300" aria-hidden="true">
                      <circle cx={1} cy={1} r={1} />
                    </svg>
                    {opening.location}
                  </dd>
                </dl>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}