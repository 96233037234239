import { Staff } from '../sections/Staff';
import { TimelineSection } from '../sections/TimelineSection';
import { LookingForHelpSection } from '../sections/LookingForHelp';

export const AboutPage = () => {
  return <div className="text-white relative isolate overflow-hidden pt-20">
    <Staff />
    <TimelineSection/>
    <LookingForHelpSection/>
  </div>
}