import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import AmountInputField from './form/AmountInputField';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function RadioSelection({contributionLevels, preSelectedLevelId, setCustomContribution, customContribution, currency, setCurrency}) {
  const [contributionLevel, setContributionLevel] = useState(contributionLevels && contributionLevels.find(level => level.id === preSelectedLevelId))

  return (
    <RadioGroup value={contributionLevel} onChange={setContributionLevel}>
      <RadioGroup.Label className="text-base font-semibold leading-6 text-gray-900">
        Select a monthly amount
      </RadioGroup.Label>

      <div className="my-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 lg:grid-cols-4 sm:gap-x-4">
        {contributionLevels && contributionLevels.map((level) => (
          <RadioGroup.Option
            key={level.id}
            value={level}
            className={({ active }) =>
              classNames(
                active ? 'border-indigo-600 ring-2 ring-indigo-600' : 'border-gray-300',
                'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
              )
            }
          >
            {({ checked, active }) => (
              <>
                <span className="flex flex-1">
                  <span className="flex flex-col">
                    <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                      {level.name}
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                      {level.description}
                    </RadioGroup.Description>
                    <RadioGroup.Description as="span" className="mt-6 text-sm font-medium text-gray-900">
                      {level.price.monthly}
                    </RadioGroup.Description>
                  </span>
                </span>
                <CheckCircleIcon
                  className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-indigo-600' : 'border-transparent',
                    'pointer-events-none absolute -inset-px rounded-lg'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
      {contributionLevel === contributionLevels[3] &&
        <AmountInputField setValue={setCustomContribution} value={customContribution} currency={currency} setCurrency={setCurrency}/>}
    </RadioGroup>
  )
}
