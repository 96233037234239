import React from "react";

export const ContactThankYou = () => {
    return (
            <div className="mx-auto grid max-w-7xl relative px-6 pb-20 pt-24 sm:pt-32 lg:static text-gray-900 lg:px-8 lg:py-48">
                <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900">Vi har nu mottagit er kontaktförfrågan och återkommer så snart vi kan!</h2>
                    <div className="max-w-xl">
                        <p className="mt-6">
                            Under tiden kan ni alltid läsa på mer om våra pågående projekt för att förstå mer om vad vi gör.
                            Ni hittar dem under menyalternativet <b>Projekt</b>.
                        </p>
                    </div>
            </div>
        </div>
    )
}