import michaelRedaImage from '../assets/staff-images/shrunk/IMG_5805.jpg'
import isacJoumaaImage from '../assets/staff-images/shrunk/IMG_5797.jpg'
import fadiImage from '../assets/staff-images/shrunk/IMG_5823.jpg'
import matildaDiboImage from '../assets/staff-images/shrunk/IMG_5808.jpg'
import jhoniImage from '../assets/staff-images/shrunk/IMG_5790.jpg'
import josefImage from '../assets/staff-images/shrunk/josef-bild.jpeg'
import { useTranslation } from 'react-i18next';

const images = {
  'josef': josefImage,
  'jhoni': jhoniImage,
  'fadi': fadiImage,
  'isac': isacJoumaaImage,
  'matilda': matildaDiboImage,
  'michael': michaelRedaImage
}

export const Staff = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t('aboutPage.staffTextHeader')}</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">{t('aboutPage.staffText')}</p>
        </div>
        <ul className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2 lg:max-w-4xl lg:gap-x-8 xl:max-w-none">
          {t('aboutPage.staff', {returnObjects: true}).map((person) => (
            <li key={person.name} className="flex flex-col gap-6 xl:flex-row">
              <div className="w-52 h-64 flex-none rounded-2xl overflow-hidden">
                <img className="w-full h-full object-cover" src={images[person.id]} alt={`${person.name}`} />
              </div>
              <div className="flex-auto">
                <h3 className="text-lg font-semibold leading-8 tracking-tight text-gray-900">{person.name}</h3>
                <p className="text-base leading-7 text-gray-600">{person.role}</p>
                <p className="mt-6 text-base leading-7 text-gray-600">{person.bio}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
