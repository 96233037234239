export const ROUTE_URI = {
  HOME: '/',
  PROJECTS: {
    EGYPT: '/projects/egypt',
    LEBANON: '/projects/lebanon',
    SYRIA: '/projects/syria'
  },
  ABOUT: '/about',
  CONTACT: '/contact',
  CONTRIBUTIONS: '/contributions',
  STATUES: '/statues',
  NOT_FOUND: '/404',
  THANK_YOU: '/thank-you',
}
