import React from 'react'
import {Navbar} from "./components/Navbar";
import {Footer} from "./components/Footer";
import {Navigate, Route, Routes} from "react-router-dom";
import {MainPage} from "./pages/MainPage";
import {ContributionsPage} from "./pages/ContributionsPage";
import {NotFoundPage} from "./pages/NotFoundPage";
import {ContactPage} from "./pages/ContactPage";
import {EgyptProjectPage} from "./pages/projects/EgyptProjectPageNew";
import {LebanonProjectPage} from "./pages/projects/LebanonProjectPageNew";
import {SyriaProjectPage} from "./pages/projects/SyriaProjectPageNew";
import {StatuesPage} from "./pages/StatuesPage";
import {AboutPage} from "./pages/AboutPage";
import {ROUTE_URI} from "./utils/RouteLinks";
import './i18n';
import {useTranslation} from "react-i18next";
import {ThankYouPage} from "./pages/ThankYouPage";

const PageRoutes = () => {
    return <Routes>
        <Route index path={ROUTE_URI.HOME} element={<MainPage/>}/>
        <Route path={ROUTE_URI.CONTRIBUTIONS} element={<ContributionsPage/>}/>
        <Route path={ROUTE_URI.CONTACT} element={<ContactPage/>}/>
        <Route path={ROUTE_URI.ABOUT} element={<AboutPage/>}/>
        <Route path={ROUTE_URI.PROJECTS.SYRIA} element={<SyriaProjectPage/>}/>
        <Route path={ROUTE_URI.PROJECTS.EGYPT} element={<EgyptProjectPage/>}/>
        <Route path={ROUTE_URI.PROJECTS.LEBANON} element={<LebanonProjectPage/>}/>
        <Route path={ROUTE_URI.STATUES} element={<StatuesPage/>}/>
        <Route path={ROUTE_URI.NOT_FOUND} element={<NotFoundPage/>}/>
        <Route path={ROUTE_URI.THANK_YOU} element={<ThankYouPage/>}/>
        <Route path="*" element={<Navigate to={ROUTE_URI.NOT_FOUND} replace/>}/>
    </Routes>
}

export const App = () => {
    const { i18n } = useTranslation();
    document.body.dir = i18n.dir();

    return (
        <div className="bg-white flex flex-col h-screen justify-between">
            <Navbar/>
            <div className="flex-grow">
                <PageRoutes/>
            </div>
            <Footer/>
        </div>
    )
}
