import React from 'react';
import { useTranslation } from 'react-i18next';
import { getTextAlignment } from '../../utils/StyleUtils';

export const ProjectV1 = ({
                            image,
                            texts,
                            header,
                            facilityName,
                            ourRoles,
                            theirRoles,
                            contactInformation,
                            reasons,
                            helpUsText
                          }) => {
  const { t, i18n  } = useTranslation()

  const getContactInfo = () => {
    return (
      <div className="gap-0 mt-5">
        <p
          className="text-base leading-7 font-semibold tracking-tight text-gray-900">{t('projectsPage.contactInformationTitle')}:</p>
        {contactInformation.name && <p className="text-base leading-7 tracking-tight text-gray-900">{contactInformation.name}</p>}
        {contactInformation.phone && <p style={getTextAlignment(i18n.language)} className="text-base leading-7 tracking-tight text-gray-900">{contactInformation.phone}</p>}
        {contactInformation.website && <a href={contactInformation.website}
                                          className="text-base leading-7 tracking-tight text-blue-500">{contactInformation.website}</a>}
      </div>
    )
  }

  const getHelpList = (helpingParty, roles) => {
    return (
      <dl className="relative flex flex-wrap gap-x-3 py-8">
        <dd className="w-full flex-none text-lg font-semibold tracking-tight text-gray-900">
          {helpingParty} {t('projectsPage.helpingWith')}
          {roles.map(role => (
            <dd className="mt-4 flex items-center gap-x-3 text-base leading-7 text-gray-600">

              <svg viewBox="0 0 2 2" className="h-1.5 w-1.5 flex-none fill-gray-300"
                   aria-hidden="true">
                <circle cx={1} cy={1} r={1} />
              </svg>
              {role}
            </dd>
          ))}
        </dd>
      </dl>
    )
  }

  return (
    <div className="bg-white py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div
          className="mx-auto flex max-w-2xl flex-col items-start justify-between gap-16 lg:mx-0 lg:max-w-none lg:flex-row">
          <div className="w-full lg:max-w-lg lg:flex-auto">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{header}</h2>
            {texts && texts.map(text => <p className="mt-6 text-lg leading-8 text-gray-600">{text}</p>)}
            {reasons &&
              <ol className="mt-6 ml-8 list-decimal gap-4">
                {reasons.map(reason => <li className="text-base leading-8 text-gray-600">{reason}
                </li>)}
              </ol>
            }
            {contactInformation && getContactInfo()}
            <img
              src={image}
              alt=""
              className="mt-16 aspect-[6/5] w-full rounded-2xl bg-gray-50 object-cover lg:aspect-auto lg:h-[34.5rem]"
            />
          </div>
          <div className="w-full lg:max-w-xl lg:flex-auto">
            <ul className="-my-8 divide-y divide-gray-100">
              {getHelpList(facilityName, theirRoles)}
              {getHelpList("One Hand", ourRoles)}
            </ul>
            {helpUsText && <div className="mt-8 w-full border-t border-gray-100 pt-8">
              {helpUsText.map((text, index) => <p className={`w-full flex-none text-base leading-7 text-gray-600 ${index === 1 && "mt-4"}`}>{text}</p>)}
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
