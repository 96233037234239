import { RadioGroup } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CreditCardIcon } from '@heroicons/react/24/solid'
import PayPalLogo from '../assets/paypal-mark-color.svg'
import AutoGiroModal from '../components/AutoGiroModal';

const tiersList = (t) => [
  {
    name: t('contributionPage.levels.0.name'),
    id: 'level-essential',
    href: '#',
    price: {
      oneTime:  t('contributionPage.levels.0.prices.oneTime'),
      monthly: t('contributionPage.levels.0.prices.monthly'),
      annually: t('contributionPage.levels.0.prices.annually')
    },
    description: t('contributionPage.levels.0.text'),
    features: t('contributionPage.levels.0.features', { returnObjects: true }),
    mostPopular: false,
  },
  {
    name: t('contributionPage.levels.1.name'),
    id: 'level-future',
    href: '#',
    price: {
      oneTime:  t('contributionPage.levels.1.prices.oneTime'),
      monthly: t('contributionPage.levels.1.prices.monthly'),
      annually: t('contributionPage.levels.1.prices.annually')
    },
    description: t('contributionPage.levels.1.text'),
    features: t('contributionPage.levels.1.features', { returnObjects: true }),
    mostPopular: true,
  },
  {
    name: t('contributionPage.levels.2.name'),
    id: 'level-love',
    href: '#',
    price: {
      oneTime:  t('contributionPage.levels.2.prices.oneTime'),
      monthly: t('contributionPage.levels.2.prices.monthly'),
      annually: t('contributionPage.levels.2.prices.annually')
    },
    description: t('contributionPage.levels.2.text'),
    features: t('contributionPage.levels.2.features', { returnObjects: true }),
    mostPopular: false,
  },
  {
    name: t('contributionPage.levels.3.name'),
    id: 'level-custom',
    href: '#',
    price: {
      oneTime:  t('contributionPage.levels.3.prices.oneTime'),
      monthly: t('contributionPage.levels.3.prices.monthly'),
      annually: t('contributionPage.levels.3.prices.annually')
    },
    description: t('contributionPage.levels.3.text'),
    features: t('contributionPage.levels.3.features', { returnObjects: true }),
    mostPopular: false,
  },
]


const frequenciesList = (t) => [
  { value: 'oneTime', label: t('contributionPage.occurrence.oneTime'), priceSuffix: '' },
  { value: 'monthly', label: t('contributionPage.occurrence.monthly'), priceSuffix: `/${t('contributionPage.occurrence.monthlySuffix')}` },
  { value: 'annually', label: t('contributionPage.occurrence.annually'), priceSuffix: `/${t('contributionPage.occurrence.annuallySuffix')}` },
]
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const ContributionsLevelsSection = () => {
  const { t, i18n } = useTranslation()
  const initialRender = useRef(i18n.language);
  const [frequencies, setFrequencies] = useState(frequenciesList(t))
  const [frequency, setFrequency] = useState(frequencies[1])
  const [tiers, setTiers] = useState(tiersList(t))
  const [isModalOpen, setModalOpen] = useState(false);
  const [autoGiroSelection, setAutoGiroSelection] = useState();
  const [isClicked, setIsClicked] = useState({
    'level-essential': false,
    'level-future': false,
    'level-love': false,
    'level-custom': false
  });

  useEffect(() => {
    if (initialRender.current === i18n.language) {
      return
    }
    let translatedFrequencies = frequenciesList(t);
    setFrequencies(translatedFrequencies)
    setTiers(tiersList(t))
    setFrequency(translatedFrequencies.find(f => frequency.value === f.value))
    initialRender.current = i18n.language
  }, [t, i18n, frequency]);

  const levelsTexts = i18n.t('contributionPage.levelsText', { returnObjects: true });

  const buttonClass = (popular) => (popular
    ? 'bg-indigo-600 text-white shadow-sm hover:bg-indigo-500'
    : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300');

  const commonClasses = 'mt-6 block py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600';
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <p
            className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">{t('contributionPage.levelsTextHeader')}</p>
        </div>
        <p className="mx-auto mt-6 max-w-3xl text-center text-lg leading-8 text-gray-600">
            {levelsTexts.map((text, index) => (
              <span key={index}>
          {text}
                {index !== levelsTexts.length - 1 && <><br /><br /></>}
        </span>
            ))}


        </p>
        <div className="mt-16 flex justify-center">
          <RadioGroup
            value={frequency}
            onChange={setFrequency}
            className="grid grid-cols-3 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
          >
            <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
            {frequencies.map((option) => {
              return ( <RadioGroup.Option
                key={option.value}
                value={option}
                className={() =>
                  classNames(
                      frequency && frequency.value === option.value ? 'bg-indigo-600 text-white' : 'text-gray-500',
                    'cursor-pointer rounded-full px-2.5 py-1'
                  )
                }
              >
                <span>{option.label}</span>
              </RadioGroup.Option>
              )})}
          </RadioGroup>
        </div>
        <AutoGiroModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          preselectedValue={autoGiroSelection}
          contributionLevels={tiers}
          frequency={frequency}
        />
        <div
          className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular ? 'ring-2 ring-indigo-600' : 'ring-1 ring-gray-200',
                'rounded-3xl p-8',
              )}
            >
              <div className="flex items-center justify-between gap-x-4">
              <h3
                id={tier.id}
                className={classNames(
                  tier.mostPopular ? 'text-indigo-600' : 'text-gray-900',
                  'text-lg font-semibold leading-8'
                )}
              >
                {tier.name}
              </h3>
              {tier.mostPopular ? (
                <p className="text-center rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600">
                  {t('contributionPage.mostPopular')}
                </p>
              ) : null}
              </div>
              <p className="mt-4 text-sm leading-6 text-gray-600">{tier.description}</p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold tracking-tight text-gray-900">{tier.price[frequency.value]}</span>
                {frequency.priceSuffix &&
                  <span className="text-sm font-semibold leading-6 text-gray-600">{frequency.priceSuffix}</span>}
              </p>
              {!isClicked[tier.id] ? (<button
                onClick={() => setIsClicked({...isClicked, [tier.id]: !isClicked[tier.id]})}
                aria-describedby={tier.id}
                className={classNames(buttonClass(tier.mostPopular), commonClasses, 'w-full', 'rounded-md')}>
                {t('contributionPage.donate')}
              </button>) : (
                <div className="flex">
                  {frequency.value !== frequencies[0].value && <button
                    className={classNames(buttonClass(tier.mostPopular), commonClasses,'w-full rounded-l-md')}
                    onClick={() => {
                      setAutoGiroSelection(tier.id);
                      setModalOpen(true);
                    }}
                  >
                    Autogiro
                  </button>}
                  <Link
                    to="https://www.paypal.com/donate/?hosted_button_id=M6JPCZRPP5GGG" // replace 'your-link-url' with your actual link
                    style={{ backgroundColor: 'rgb(255, 209, 64)' }}
                    className={classNames(buttonClass(tier.mostPopular),
                      commonClasses,
                      'w-full text-indigo-600 rounded-r-md border-l border-white flex place-content-center background-color: rgb(255 209 64)')}
                  >
                    <img src={PayPalLogo} alt={'paypal-logo-payment'} className={classNames('max-h-6')}/>
                    <span className={classNames('text-indigo-600')}>&nbsp;{'|'}&nbsp;</span>
                    <CreditCardIcon className={classNames('max-h-6 text-indigo-600')}/>
                  </Link>
                </div>
              )}
              <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}